import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import * as d3 from "d3";
import "./geo.scss";
import OEVKS from "../../data/oevk.geo.simplified.d3.json";
import Master from "../../data/data-geo2.json";
import { Dictionary, mapMasterToOEVK, placeRatio } from "../util/GeoModels";
import { transcode } from "../util/helper";
import { useMemo, useWindowSize } from "../util/CustomReactHooks";

const COLORS = {
	selected: "#1a237e",
	hover: "#2979ff",
};

Master.sort((a, b) => a.OEVK.localeCompare(b.OEVK));

// const OEVK_LIST = OEVKS.features.map((f) => f.properties.name);

type ColorSchema = "Default" | "Billego";

const OEVK_TO_MASTER_MAPPING: Dictionary = Master.reduce((aggr, curr) => {
	aggr[mapMasterToOEVK(curr.OEVK)] = curr.OEVK;
	return aggr;
}, {} as Dictionary);

const getMasterByOevk = (oevkName: string) => {
	return Master.find((m) => m.OEVK === OEVK_TO_MASTER_MAPPING[oevkName]);
};

const GeoPage = () => {
	// HELPER

	// COMPONENT

	const [selectedOEVKFeature, setSelectedOEVKFeature] = useState<
		GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> | undefined
	>(undefined);
	const [selectedOEVK, setSelectedOEVK] = useState<string>();
	const [colorSchema, setColorSchema] = useState<ColorSchema>("Default");
	const windowSize = useWindowSize();
	const mapWidth = useMemo(() => {
		return windowSize[0] < 750 ? windowSize[0] - 32 : 750;
	}, [windowSize]);

	const updateSVG = () => {
		const width = mapWidth;
		const height = (500 * mapWidth) / 750;

		const color = d3
			.scaleLinear()
			.domain([1, 20])
			.clamp(true)
			.range(["#fff", "#fcf883"] as any);

		const projection = d3
			.geoMercator()
			.scale(2500 + 8.3 * (mapWidth - 328))
			// Center the Map to Hungary
			.center([19.5, 47.16])
			.translate([width / 2, height / 2]);

		const path = d3.geoPath(projection);

		const svg = d3.select(".hungary").attr("width", width).attr("height", height);

		const mapLayer = svg.select(".map-layer");
		// const effectLayer = svg.select(".effect-layer");

		function oevkRectClicked(this: d3.BaseType) {
			mapLayer
				.selectAll("path")
				.style("fill", (d) => getColor(d as GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>));

			setSelectedOEVKFeature(undefined);
		}

		function oevkClicked(
			this: d3.BaseType | SVGPathElement,
			_e: Event,
			f: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
		) {
			setSelectedOEVKFeature(selectedOEVKFeature !== f ? f : undefined);

			mapLayer.selectAll("path").style("fill", function (d) {
				return selectedOEVKFeature && d === selectedOEVKFeature
					? COLORS.selected
					: getColor(d as GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>);
			});
		}

		function oevkMouseOver(this: d3.BaseType | SVGPathElement) {
			d3.select(this).style("fill", COLORS.hover);
		}

		function oevkMouseOut(this: d3.BaseType | SVGPathElement, _e: Event) {
			mapLayer.selectAll("path").style("fill", (d) => {
				return selectedOEVKFeature && d === selectedOEVKFeature
					? COLORS.selected
					: getColor(d as GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>);
			});
		}

		const getColor = (f: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>): any => {
			if (colorSchema === "Default") {
				const num = f && f.properties ? f.properties.name.length : 0;
				return color(num);
			}

			if (colorSchema === "Billego") {
				let c = "#fff";
				const master = getMasterByOevk(f?.properties?.name);
				if (master !== undefined) {
					if (master["21 kk"] === "Kormánypárti") {
						c = "#ffe3cc";
					}
					if (master["21 kk"] === "Ellenzéki") {
						c = "#abc9ff";
					}
					if (master["21 kk"] === "Billegő") {
						c = "red";
					}
				}

				return c;
			}
		};

		svg.select("rect").attr("width", width).attr("height", height).on("click", oevkRectClicked);

		const mapData = OEVKS as GeoJSON.FeatureCollection;

		// normalize coropleth scale
		// color.domain([0, d3.max(mapData.features, getColor)]);

		mapLayer
			.selectAll("path")
			.data(mapData.features)
			.join("path")
			.attr("d", path)
			.attr("vector-effect", "non-scaling-stroke")
			.style("fill", (f) => (f === selectedOEVKFeature ? COLORS.selected : getColor(f)))
			.on("mouseover", oevkMouseOver)
			.on("mouseout", oevkMouseOut)
			.on("click", oevkClicked);
	};

	useEffect(() => {
		updateSVG();
		setSelectedOEVK(selectedOEVKFeature?.properties?.name);
	}, [selectedOEVKFeature, colorSchema, mapWidth]);

	const templateMaster = (oevkName: string) => {
		const master = getMasterByOevk(oevkName);
		if (master) {
			return (
				<div>
					<div>
						<div className="font-bold">
							{oevkName} ({transcode(master.OEVK).substring(6)})
						</div>
						<div className="mt-4">
							<div>Párt: {transcode(master.Párt)}</div>
							<div>Győztes: {transcode(master.Győztes)}</div>
							<div>Nyert %: {master["Nyert %"]}</div>
							<div>21 KK: {master["21 kk"]}</div>
							<div>Részvétel: {master.Reszvetel}</div>
							<div>Főváros: {placeRatio(master.Fovaros, master)}</div>
							<div>Város: {placeRatio(master.Varos, master)}</div>
							<div>Falu: {placeRatio(master.Falu, master)}</div>
						</div>
					</div>
				</div>
			);
		} else {
			return <div></div>;
		}
	};

	const templateButtonBar = () => (
		<div className="flex justify-around">
			<div className="space-x-2">
				<button
					disabled={colorSchema === "Default"}
					className="m-2 btn btn-primary btn-small"
					onClick={() => setColorSchema("Default")}
				>
					Megye
				</button>
				<button
					disabled={colorSchema === "Billego"}
					className="m-2 btn btn-primary btn-small"
					onClick={() => setColorSchema("Billego")}
				>
					Billegő
				</button>
			</div>
		</div>
	);

	return (
		<Layout>
			<SEO title="Geo" />
			<div className="container px-4">
				<div className="flex space-x-2 mt-4">
					<div style={{ width: mapWidth + "px" }}>
						<svg className="hungary">
							<rect className="background"></rect>
							<g>
								<g className="effect-layer"></g>
								<g className="map-layer"></g>
							</g>
						</svg>
						{templateButtonBar()}
					</div>
					<div className="hidden md:block mb-8" style={{ width: "380px" }}>
						{selectedOEVK !== undefined && <div>{templateMaster(selectedOEVK)}</div>}
					</div>
				</div>
				<div className="md:hidden my-4">
					{selectedOEVK !== undefined && <div>{templateMaster(selectedOEVK)}</div>}
				</div>
			</div>
		</Layout>
	);
};

export default GeoPage;
